#education {
  padding: 50px 0;
  background-color: #181A1B;
  border-top: 2px solid #00001c;
}

#education h2 {
  color: white;
}

.education-block {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    color: white;
}

.education-block h3 {
  float: left;
}

.education-block span {
  float: right;
}

.education-block h4 {
  font-weight: 500;
  clear: both;
}

