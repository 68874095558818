footer {
    background-color: #181A1B;
    color: #fff;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-icons img {
    font-size: 24px;
    margin-left: 10px;
    width:40px;
    height:40px;

  }
  
  .footer-icons a:hover img {
    opacity: 0.5;
  }