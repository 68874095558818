.projects-block {
  padding: 50px 0;
  background-color: #333;
  color: white;
  border-top: 2px solid #00001c;
}


.project {
  display: flex;
  align-items: center;
  background-color: gray;
  margin: 10px;
  padding: 10px;
  background-color: #181A1B;
}


.project-image {
  width: 33%;
}

.project-info {
  width: 66.66%;
  text-align: center;
  padding: 10px;
}

.project-info h2 {
  /* margin-top:-150px; */
}

.project-link {
  display: block;
  margin-top: 10px;
}

.projects-container {
  margin: 0 auto;
  max-width: 60%;
  box-sizing: border-box;
}

.heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.project-image {
  transition: transform 0.2s;
}

.project-image:hover {
  transform: scale(1.1);
}




