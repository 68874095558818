#intro {
    height: 100vh;
    background-image: url("../../assets/images/background-image.jpg"); 
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  #intro-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  #intro-content h1 {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    margin: 10px;
  }
  
  #intro-content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: white;
    margin: 10px;
  }

  #intro-content #button-area {
    margin: 20px;
  }
  
  #intro-content a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: 2px solid white;
    color: white;
    text-decoration: none;
  }
  
  #intro-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5); /* adjust the opacity as needed */
    pointer-events: none;
  }
  
  #intro-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  #intro-down span {
    display: block;
  }
  
  #intro-down i {
    font-size: 2rem;
    color: white;
  }

.head-container {
  display: flex;
  justify-content: center;
}

#button-area {
  display: flex;
  justify-content: center;
}
